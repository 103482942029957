<template>
    <mega-card :title="$t('round_pos')">

        <div class="card-media ratio" :style="{paddingBottom: padding+'%'}">
            <gmap-map class="fill-parent"
                    :center="{lat: lat, lng: lng}"
                    :zoom="17"
                    map-type-id="terrain"
                    style="position: absolute;">

                <gmap-marker :position="{lat: lat, lng: lng}"/>
            </gmap-map>
        </div>

        <div class="card-body">
            <p class="mb-0 d-inline mr-2">{{place}}</p>
            <small class="text-muted">{{address}}</small>
        </div>
    </mega-card>
</template>

<script>
    export default {
        name: 'ModelNewPage',
        props: {
            lat: {
                type: Number,
                required: true
            },
            lng: {
                type: Number,
                required: true
            },
            place: {
                type: String
            },
            address: {
                type: String
            },
            ratio: {
                type: String
            }
        },
        computed: {
            padding() {
                let r = this.ratio ? this.ratio.toString().split('x') : [4, 3];

                return (r[1] / r[0]) * 100;
            }
        }
    }
</script>
